/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpSpendMoneyStates(
  $stateProvider: ng.ui.IStateProvider
): void {
  'ngInject';

  $stateProvider.state('spend-money', {
    parent: 'root',
    url: '/spend-money',
    // Add resolve data as attributes: <acp-spend-money data="$resolve.fetchedData" ... />
    template: '<acp-spend-money />',
    // permissions will need to be set, but in another story.
    data: {
      permissions: {
        // except: [],
        // only: []
      }
    },
    resolve: {
      module($ocLazyLoad) {
        'ngInject';

        return import(/* webpackChunkName: "acp.section.spend-money" */ './index').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}
